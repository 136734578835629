import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import {
    OgGatsbyBanner,
} from '../components/organisms';
import CategoryProducts from '../components/organisms/WordPress/CategoryProducts';

import { McBreadcrumbs } from '../components/molecules';

export default function service() {
    const data = useStaticQuery(query);
    const incrementCount = 8;
    const [displayCount, setDisplayCount] = useState(incrementCount);

    const addCount = () => {
        setDisplayCount(prev => prev + incrementCount);
    }

    return (
        <>
            <OgGatsbyBanner image={data.allFile.edges[0].node.childrenImageSharp[0]} alt={data.allFile.edges[0].node.name} link="" maxWidth={"full"} marginBottom={"10"} />
            <McBreadcrumbs listItem='お知らせ' listUrl='/news' />
            <div className="inner">
                <CategoryProducts title={"イベント一覧"} slug="event" displayCount={displayCount} />
            </div>
            <button onClick={() => addCount()}>カウントアップ</button>
        </>
    )
}

const query = graphql`
query {
  allFile (filter: {name:{eq:"img_news"}}){
    edges {
      node {
        name
        childrenImageSharp {
            gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
}
`
