import React from 'react';
import { Link } from 'gatsby';
import dateFormat from 'dateformat';

import {
  wrapDiv,
  ul,
  li,
  img,
  link,
  imgDiv,
  text,
  tag,
  recruitingTag,
  tagList,
  activeTag,
  closeTag,
  ribbon,
  month,
  day,
} from './CategoryProducts.css';

interface Props {
  title: string;
  slug: 'support' | 'logo' | 'event' | 'blog';
  displayCount: number;
  isDisplayTitle?: boolean;
  isDisplayText?: boolean;
  isDisplayButton?: boolean;
  dataSrc: any;
}

const CollectionSelection = React.memo(
  ({
    dataSrc,
    slug,
    title,
    displayCount,
    isDisplayTitle = true,
    isDisplayText = true,
    isDisplayButton = true,
  }: Props) => {
    const months = [
      'JAN.',
      'FEB.',
      'MAR.',
      'APR.',
      'MAY.',
      'JUN.',
      'JUL.',
      'AUG.',
      'SEP.',
      'OCT.',
      'NOV.',
      'DEC.',
    ];
    const nowDatetime = Date.parse(new Date());

    return (
      <>
        <div className={wrapDiv}>
          <ul className={ul}>
            {dataSrc &&
              dataSrc.map((item: any) => {
                return (
                  <>
                    <li key={item.node.id} className={li}>
                      <div className={ribbon}>
                        <small className={month}>
                          {
                            months[
                              new Date(
                                item.node.displayEndDate?.displayenddate,
                              ).getMonth()
                            ]
                          }
                        </small>
                        <span className={day}>
                          {new Date(
                            item.node.displayEndDate?.displayenddate,
                          ).getDate()}
                        </span>
                      </div>
                      <Link
                        className={link}
                        to={`/${slug}/post${item.node.uri}`}
                      ></Link>
                      <div className={imgDiv}>
                        <img
                          className={img}
                          src={item.node.featuredImage?.node.sourceUrl}
                          alt={item.node.title}
                        />
                      </div>
                      <div className={recruitingTag}>
                        {!item.node.displayEndDate?.displayenddate ||
                        Date.parse(item.node.displayEndDate?.displayenddate) >
                          nowDatetime ? (
                          <div className={activeTag}>募集中</div>
                        ) : (
                          <div className={closeTag}>終了</div>
                        )}
                      </div>
                      {isDisplayTitle && (
                        <h3 className={text}>{item.node.title}</h3>
                      )}
                      <div className={tagList}>
                        {item.node.tags?.edges.map((tagItem: any) => {
                          return (
                            <>
                              <div id={tagItem.node.id} className={tag}>
                                {tagItem.node.name}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div>
                        Posted {dateFormat(item.node.date, 'yyyy.mm.dd')}
                      </div>
                    </li>
                  </>
                );
              })}
          </ul>
          {isDisplayButton && (
            <div style={{ textAlign: 'center' }}>
              <Link
                to={'/event'}
                style={{
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  padding: '2rem 10rem',
                  display: 'inline-block',
                  fontSize: '15px',
                }}
              >
                もっと見る
              </Link>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default CollectionSelection;
